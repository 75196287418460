/* src/App.css */
html, body, #root {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #333;
  background-color: #f5f7fa;
}

.appContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.mainContainer {
  flex: 1;
  display: flex;
  height: 70vh; /* Adjust based on your navbar height */
  overflow: hidden;
}

/* Modern color palette */
:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --accent-color: #e74c3c;
  --background-color: #f5f7fa;
  --text-color: #333;
  --light-text-color: #777;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  color: var(--text-color);
}

/* Buttons */
.button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.primaryButton {
  background-color: var(--primary-color);
  color: white;
}

.primaryButton:hover {
  background-color: #2980b9;
}

.secondaryButton {
  background-color: var(--secondary-color);
  color: white;
}

.secondaryButton:hover {
  background-color: #27ae60;
}

/* Card styles */
.card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
}

/* Input styles */
input[type="text"],
input[type="password"],
textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

input[type="text"]:focus,
input[type="password"]:focus,
textarea:focus {
  border-color: var(--primary-color);
  outline: none;
}

/* Animations */
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.fadeIn {
  animation: fadeIn 0.5s ease-in-out;
}

/* Responsive design */
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }

  .card {
    padding: 15px;
  }
}

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";
