@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

/* Global Styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family:"sometype mono";
}

.homePage {
  background: rgb(198 219 188);
  color: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  overflow: hidden;
}

/* Header Section */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  font-size: 1.5rem;
  font-weight: bold;
  color: #0b0909;
  font-family:"sometype mono";

}

.loginButton {
  background-color: #ffeb3b; /* Yellow */
  border: none;
  border-radius: 8px;
  color: #000;
  font-size: 1rem;
  font-weight: 500;
  padding: 10px 20px;
  cursor: pointer;
}

.loginButton:hover {
  background-color: #ffe600; /* Slightly darker yellow on hover */
  transform: scale(1.05); /* Scale up slightly */
}

/* Content Section */
.content {
  position: absolute;
  top: 25%; /* Move the section upwards */
  left: 30%;
  text-align: left;
  max-width: 600px;
  font-family:"sometype mono";
}

.title {
  font-size: 4rem;
  font-weight: 1000;
  line-height: 1.2;
  color: #110e0e;
  font-family:"sometype mono";
}

.subtitle {
  font-size: 1.2rem;
  margin: 20px 0;
  color: #0c0d0f;
  font-family:"sometype mono";
}

.tryButton {
  display: flex;
  align-items: center; /* Center the icon and text vertically */
  background-color: white; /* White background for the button */
  color: black; /* Text color */
  padding: 10px 15px;
  border: 2px solid #ccc; /* Optional border */
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition for hover effects */
}
.googleIcon {
  width: 20px; /* Set the width of the Google icon */
  height: 20px; /* Set the height of the Google icon */
  margin-right: 8px; /* Space between icon and text */
}
.tryButton:hover {
  background-color: #f0f0f0; /* Light gray on hover */
  transform: scale(1.05); /* Slightly enlarge button on hover */
}

/* Loading Screen */
.loadingScreen {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
  font-size: 1.5rem;
  animation: fadeIn 0.6s ease-in-out;
}

/* Error */
.error {
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: #e74c3c;
  background: #fdecea;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
}

/* Spiral Wavy Pattern */
.homePage::before {
  content: "";
  position: absolute;
  top: 40%;
  left: 0;
  right: 0;
  height: 300px;
  background: radial-gradient(
      circle at center,
      rgba(255, 255, 255, 0.1),
      transparent 70%
    ),
    repeating-conic-gradient(
      from 0deg,
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.15) 5px,
      transparent 5px,
      transparent 10px
    );
  border-radius: 50%;
  opacity: 0.8;
  z-index: 0;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}


.linkText {
   /* or any other color you want */
  text-decoration: underline;
  font-weight: bold;
}