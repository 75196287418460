/* src/Styles/ResizableOutput.module.css */
.outputContainer {
  position: relative;
  bottom: 0;
  left: 0;
  background-color: #1e1e1e;
  border-top: 1px solid #333;
  display: flex;
  flex-direction: column;
  z-index: 100;
  width : 100%;
  height :100%;
}



.outputHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #252526;
  flex: 1; /* Ensures the header takes up full width */
}

.outputHeader h3 {
  margin: 0;
  font-size: 1.5rem; /* Increase font size */
  color: #ffffffab; /* Change color to white for better contrast */
  font-weight: bold; /* Make the text bold */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.7); /* Optional: add a subtle shadow for better visibility */
}

.closeButton {
  background-color: #0e639c;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;
  transition: background-color 0.2s;
}

.closeButton:hover {
  text-decoration: underline;
}

.outputContent {
  flex: 4;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 10px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-style: italic;
  color: #888;
}