/* src/Styles/Navbar.module.css */
.navbar {
    background-color:rgb(198 219 188);
    padding: 15px 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.title {
    color: #060808;
    font-family: 'sometype mono' ;
    font-size: 24px;
    font-weight: bold;
    margin: 0;
}

.navLinks {
    display: flex; /* Use flexbox for horizontal alignment */
    gap: 20px;
    align-items: center; /* Align items vertically in center */
  }

.userMenu {
    position: relative; /* Required for dropdown positioning */
    
}

.userButton {
    background: none;
    border: none;
    color: #ecf0f1;
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer;
}

.dropdown {
    position: absolute;
    right: 0;
    top: 100%;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    z-index: 1000; /* Ensure dropdown is above other elements */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.username {
    color: #2c3e50;
    font-weight: bold;
    margin-bottom: 10px;
}

.logoutButton {
    padding: 8px 16px;
    border: none;
    background-color: #e74c3c;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.logoutButton:hover {
    background-color: #c0392b; /* Darker shade on hover */
}


.feedbackSection {
    display: flex;
    align-items: center;
    gap:  10px;
}

.feedbackText {
    font-size: 18px;
    color: #060808;
    font-family: 'sometype mono' ;
    
    font-weight: bold;
    margin: 0;
}

.feedbackButton {
    background-color: #4CAF50; /* Green */
    color: white;
    border: none;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
}

.feedbackButton:hover {
    background-color: #45a049; /* Darker green on hover */
    transform: scale(1.05); /* Slightly enlarge button on hover */
}
.syllabus {
    display: flex;
    overflow: hidden; 
  }
  


