.chat {
    display: flex;
    align-items: center;
    padding: 12px;
    background-color: #f5f7f9; /* Light background for the chat section */
    border-top: 2px solid #d1d5da; /* Subtle border at the top */
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Shadow for depth */
}

.chatContainer {
    position: relative; /* Required for positioning the arrow inside the input box */
    width: 100%; /* Ensures the input takes all available width */
}

.input {
    width: 100%; /* Full width input */
    padding: 12px 40px 12px 12px; /* Space for the arrow on the right */
    border: 1px solid #d1d5da;
    border-radius: 25px; /* Rounded input for modern UI */
    font-size: 14px;
    outline: none;
    background-color: #ffffff;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.input:focus {
    border-color: #1f3b4d;
    box-shadow: 0 0 5px rgba(31, 59, 77, 0.3); /* Highlighted border on focus */
}

.arrowButton {
    position: absolute; /* Absolute positioning relative to the chatContainer */
    right: 10px; /* Space from the right edge of the input box */
    top: 50%; /* Vertically centered */
    transform: translateY(-50%); /* Adjust for exact centering */
    width: 24px;
    height: 24px;
    background-color: #1f3b4d;
    border: none;
    border-radius: 50%; /* Circular button */
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff;
    transition: background-color 0.3s ease, transform 0.2s ease;
}



.arrowButton::before {
    content: '➤'; /* Unicode arrow icon */
    font-size: 14px;
    color: #ffffff;
}
