/* language.module.css */
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  color: #333;
}

.card {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  width: 100%;
  max-width: 1200px;
  height : auto;
  margin: 0 auto;
  border: 1px solid #e0e0e0;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1.5rem;
  padding: 1.5rem 0;
  justify-items: center;
}

.languageCard {
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid #e0e0e0;
  text-align: center;
  min-height: auto;
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.languageCard:hover {
  transform: scale(1.05); /* Slightly enlarge the card on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add a subtle shadow on hover */
  background-color: #f7f7f7; /* Change the background color on hover */
}
/* Rest of your existing CSS remains the same */
/* ... (keep other styles unchanged) */

@media (max-width: 768px) {
  .container {
    padding: 1rem;
  }
  
  .card {
    padding: 1rem;
  }
  
  .gridContainer {
    grid-template-columns: 1fr;
    gap: 1rem;
  }
  
  .languageCard {
    min-height: auto;
    padding: 1rem;
  }
}


.progressBar {
  background: #e0e0e0;
  border-radius: 8px;
  height: 8px;
  margin: 0.5rem 0;
  width: 100%; /* Changed from 24% to full width */
  overflow: hidden;
}

.progressFill {
  background: #4CAF50;
  height: 100%;
  transition: width 0.3s ease;
}