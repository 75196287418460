/* src/Styles/ResizableContainer.module.css */
.container {
  display: flex;
   /* Occupy the full viewport height */
  height :100%;
  width: 100%;
}

.leftPane, .rightPane {
  height : 100%;
  overflow: hidden;
}

.resizer {
  width: 6px; /* Thickness of the divider */
  cursor: ew-resize;
  background-color: #888;
  position: relative;
  z-index: 10;
}

.resizer:hover {
  background-color: #555;
}

.rightPane {
  display: flex;
  flex-direction: column;
  /* Ensure chat interface occupies full height */
}